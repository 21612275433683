<template>
  <div style="padding: 20px">
    <div class="query-list" style="padding-bottom: 20px">
      <el-row :gutter="20">
        <el-col :span="18" :offset="0">
          <el-row :gutter="20">
            <el-col :span="6" :offset="0">
              <el-input
                @keyup.enter.native="handleClickSearch"
                v-model="ucid"
                placeholder="请输入用户UCID"
                size="normal"
              ></el-input>
            </el-col>
            <el-col :span="6" :offset="0">
              <el-input
                @keyup.enter.native="handleClickSearch"
                v-model="mobile"
                placeholder="请输入手机号"
                size="normal"
              ></el-input>
            </el-col>
            <!-- <el-col :span="6" :offset="0"
              ><el-input
                v-model="email"
                placeholder="请输入邮箱"
                size="normal"
              ></el-input
            ></el-col> -->
          </el-row>
        </el-col>
        <el-col :span="6" :offset="0">
          <el-button type="primary" size="default" @click="handleClickSearch">
            查询
          </el-button>
          <el-button type="info" size="default" @click="handleClickReset">
            重置
          </el-button>
        </el-col>
      </el-row>
    </div>

    <el-table :data="list" border stripe row-key="ucid">
      <!-- sortable="custom" -->
      <el-table-column label="UCID" prop="ucid"> </el-table-column>
      <el-table-column label="昵称" prop="nickname"></el-table-column>
      <el-table-column label="持有NFT" prop="hold_nft_count">
        <template slot-scope="scope">
          <el-link type="primary" @click="handleGoNFT(scope.row)">
            {{ scope.row.hold_nft_count }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column label="购买NFT" prop="buy_nft_count">
        <template slot-scope="scope">
          <el-link type="primary" @click="handleGoOrderbuyer(scope.row)">
            {{ scope.row.buy_nft_count }}
          </el-link>
        </template>
      </el-table-column>
      <!-- TODO 隐藏部分 -->
      <!-- <el-table-column label="购买花费bsv" prop="spent_bsv_for_buy"></el-table-column> -->
      <el-table-column label="出售的NFT" prop="sell_nft_count">
        <template slot-scope="scope">
          <el-link type="primary" @click="handleGoOrderSeller(scope.row)">
            {{ scope.row.sell_nft_count }}
          </el-link>
        </template>
      </el-table-column>
      <!-- TODO 隐藏部分 -->
      <!-- <el-table-column label="出售获得bsv" prop="earn_bsv_for_sell"></el-table-column> -->
      <el-table-column label="当前CNY" prop="cny_balance"></el-table-column>
      <!-- TODO 隐藏部分 -->
      <!-- <el-table-column label="当前BSV" prop="bsv_balance"></el-table-column> -->
      <el-table-column label="提现金额" prop="total_withdraw_cny">
        <template slot-scope="scope">
          <el-link type="primary" @click="handleGoWidthDraw(scope.row)">
            {{ scope.row.total_withdraw_cny }}
          </el-link>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" prop="updated_at">
        <template slot-scope="scope">
          <div>{{ scope.row.updated_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="资产总额" prop="user_acount_cny">
        <template slot-scope="scope">
          <div>{{ scope.row.user_acount_cny | formatMoney }}</div>
        </template>
      </el-table-column>
      <el-table-column label="浮动盈亏" prop="user_acount_profit_cny">
        <template slot-scope="scope">
          <div>{{ scope.row.user_acount_profit_cny | formatMoney }}</div>
        </template>
      </el-table-column>
      <!-- <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="handleQueryHoldNFTs(scope.row)">
            用户详情
          </el-button>
          <el-button size="mini" type="primary" @click="handleClickReset">
            刷新数据
          </el-button>
        </template>
      </el-table-column> -->
    </el-table>
    <!-- <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div> -->
  </div>
</template>

<script>
import { getUserAsset } from "@/api/nft.js";
import dayjs from "dayjs";

export default {
  components: {},
  data() {
    return {
      // page: 1,
      // pageSize: 20,
      // total: 0,
      list: [],
      // 查询参数
      ucid: "",
      mobile: "",
      email: "",
    };
  },
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    formatMoney: function(value) {
      return value ? `+${value}` : "";
    },
  },
  mounted() {
    const search = new URLSearchParams(window.location.search);
    const id = search.get("id");
    if (id) {
      this.ucid = id;
      this.fetchData();
    }
  },
  methods: {
    getSearchParams() {
      const params = {
        // page: this.page,
        // page_size: this.pageSize,
      };

      this.ucid && (params.ucid = this.ucid);
      this.mobile && (params.mobile = this.mobile);
      this.email && (params.email = this.email);

      return params;
    },
    async fetchData() {
      try {
        const params = this.getSearchParams();
        if (Object.keys(params).length === 0) {
          this.$message.error("请输入至少一个查询条件");
          return;
        }
        const { data } = await getUserAsset(params);
        this.list = [data.data];
      } catch (error) {}
    },

    handleClickSearch() {
      // this.page = 1;
      // this.pageSize = 20;
      this.fetchData();
    },

    handleClickReset() {
      this.ucid = "";
      this.mobile = "";
      this.email = "";
      this.list = [];
    },
    handleGoOrderbuyer(row) {
      this.$router.push("/nft_management/trade?buyer_id=" + row.ucid);
    },

    handleGoOrderSeller(row) {
      this.$router.push("/nft_management/trade?seller_id=" + row.ucid);
    },
    handleGoNFT(row) {
      this.$router.push("/nft_management?owner_id=" + row.ucid);
    },
    handleGoWidthDraw(row) {
      this.$router.push("/nft_orders/withdraw?ucid=" + row.ucid);
    },
  },
};
</script>
