// import axios from "@/http.js";
import config from "@/configs/index";
import axios from "axios";

// 获取系统参数列表
export function getSystemConfigList(page, pageSize) {
  return axios({
    url: config.url + "/phoenix/admin_nft_system_parameter/nft_intl_get_system_parameter_list",
    method: "post",
    data: {
      page: page,
      page_size: pageSize,
    },
  });
}

// 获取系统参数列表
export function editSystemConfig(id, value) {
  return axios({
    url: config.url + "/phoenix/admin_nft_system_parameter/nft_intl_update_system_parameter",
    method: "post",
    data: {
      id,
      value,
    },
  });
}

// 查询经营数据日志
// range: day 日维度，week 周维度，month 月维度
export function getManageData(page, pageSize, range) {
  return axios({
    url: config.url + "/phoenix/admin_nft_manage_data/nft_intl_get_manage_data",
    method: "post",
    data: {
      page,
      page_size: pageSize,
      range,
    },
  });
}

export function getBannerPositionList(params) {
  return axios({
    url: config.url + "/phoenix/admin_nft_banner/get_banner_position_list",
    method: "post",
    data: params,
  });
}
export function editBannerPosition(params) {
  return axios({
    url: config.url + "/phoenix/admin_nft_banner/add_or_edit_banner_position",
    method: "post",
    data: params,
  });
}
export function getBannerList(params) {
  return axios({
    url: config.url + "/phoenix/admin_nft_banner/nft_intl_get_banner_list",
    method: "post",
    data: params,
  });
}

export function addBanner(banner) {
  return axios({
    url: config.url + "/phoenix/admin_nft_banner/nft_intl_add_banner",
    method: "post",
    data: banner,
  });
}
export function updateBanner(id, banner) {
  return axios({
    url: config.url + "/phoenix/admin_nft_banner/nft_intl_update_banner",
    method: "post",
    data: {
      ...banner,
      id,
    },
  });
}

export function delBanner(id) {
  return axios({
    url: config.url + "/phoenix/admin_nft_banner/nft_intl_delete_banner",
    method: "post",
    data: { id },
  });
}

export function getNFTUserList(data) {
  return axios({
    url: config.url + "/v1/user/nft/get_user_list",
    method: "post",
    data,
  });
}

export function frozenUser(user_id, status) {
  return axios({
    url: config.url + "/v1/user/list/frozen_user/manager",
    method: "post",
    data: {
      user_id,
      status,
    },
  });
}

export function getAchievemrntList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_identification/nft_intl_get_user_achievement_list",
    method: "post",
    data,
  });
}

export function setAchievemrnt(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_identification/nft_intl_process_user_achievement",
    method: "post",
    data,
  });
}

export function getRejectMsgList(type) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/nft_intl_get_quick_reply_list",
    method: "post",
    data: {
      type,
    },
  });
}

export function setRejectMsg(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/nft_intl_edit_quick_reply",
    method: "post",
    data,
  });
}

export function deleteRejectMsg(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/nft_intl_delete_quick_reply",
    method: "post",
    data,
  });
}

export function addRejectMsg(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/nft_intl_add_quick_reply",
    method: "post",
    data,
  });
}

export function getNFTList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/nft_intl_manage_work_list",
    method: "post",
    data,
  });
}
// 铸造Ucid查询
export function getUserBalance(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/nft_get_user_bsv_address",
    method: "post",
    data,
  });
}

// 铸造
export function castNFT(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/zodiac_mint_nft",
    method: "post",
    data,
  });
}

export function setNFTStatus(nft_id, status) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/nft_intl_modify_work_display_status",
    method: "post",
    data: {
      nft_id,
      status,
    },
  });
}

export function getNFTItems(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/nft_intl_manage_work_info",
    method: "post",
    data,
  });
}

export function getNFTTransferOrders(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_transfer_order/nft_intl_get_transfer_order",
    method: "post",
    data,
  });
}

export function getNFTTradeOrders(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_trade_order/nft_intl_get_trade_order",
    method: "post",
    data,
  });
}

export function exportNFTTradeOrders(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_trade_order/export_nft_intl_get_trade_order",
    method: "post",
    data,
  });
}

export function getNFTAuditRecords(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/get_audit_order_record",
    method: "post",
    data,
  });
}

export function setNFTAuditRecord(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/audit_order",
    method: "post",
    data,
  });
}

export function setNFTAuditBlockList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/update_in_black_list",
    method: "post",
    data,
  });
}

export function getNFTCategoryList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_category/nft_intl_get_category_list",
    method: "post",
    data,
  });
}

export function deleteNFTCategory(id) {
  return axios({
    url: config.url + "/phoenix/admin_nft_category/nft_intl_delete_category",
    method: "post",
    data: {
      id,
    },
  });
}

export function updateNFTCategory(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_category/nft_intl_update_category",
    method: "post",
    data,
  });
}

export function createNFTCategory(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_category/nft_intl_add_category",
    method: "post",
    data,
  });
}

// 2022年新春活动
// 新增主题
export function createNFTSubject(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_subject/nft_intl_add_subject",
    method: "post",
    data,
  });
}

// 编辑主题
export function editNFTSubject(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_subject/nft_intl_update_subject",
    method: "post",
    data,
  });
}

// 主题列表
export function getNFTSubjectList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_subject/nft_intl_get_subject_list",
    method: "post",
    data,
  });
}

// 启用/停用主题
export function setNFTSubjectStatus(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_subject/nft_intl_subject_shelf",
    method: "post",
    data,
  });
}

// 合集列表
export function getNFTCollection(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_subject/nft_intl_get_subject_collection",
    method: "post",
    data,
  });
}

// 更新专题合集
export function setNFTSubject(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_subject/nft_intl_update_subject_collection",
    method: "post",
    data,
  });
}

// v1.2版本管理
// 获取版本列表
export function getVersionList(data) {
  return axios({
    url: config.url + "/v1/upgrade_admin/get_all_version_infos",
    method: "post",
    data,
  });
}
// 新增版本
export function addVersion(data) {
  return axios({
    url: config.url + "/v1/upgrade_admin/add_version_info",
    method: "post",
    data,
  });
}
// 编辑版本
export function editVersion(data) {
  return axios({
    url: config.url + "/v1/upgrade_admin/edit_version_info",
    method: "post",
    data,
  });
}
// 删除版本
export function deleteVersion(data) {
  return axios({
    url: config.url + "/v1/upgrade_admin/delete_version_info",
    method: "post",
    data,
  });
}

// v1.2资金流水
// 获取资金列表
export function getFinanceList(data) {
  return axios({
    url: config.url + "/ikki/admin_fiat/get_all_user_fiat_historys",
    method: "post",
    data,
  });
}
// 获取资金详情
export function getFinanceInfo(data) {
  return axios({
    url: config.url + "/ikki/admin_fiat/get_any_user_fiat_history_info",
    method: "post",
    data,
  });
}
// 新增支出单
export function addFinance(data) {
  return axios({
    url: config.url + "/ikki/admin_fiat/deduct_user_fiat_by_financial_officer",
    method: "post",
    data,
  });
}
// 双花检测
export function getDoubleList(data) {
  return axios({
    url: config.url + "/ikki/admin_base/get_broadcast_tx_with_confirm_list",
    method: "post",
    data,
  });
}
// 获取提现订单列表
export function getWithdrawList(data) {
  return axios({
    url: config.url + "/ikki/admin_fiat/get_withdraw_history",
    method: "post",
    data,
  });
}
// 提现重试
export function retryWithdrawOrders(data) {
  return axios({
    url: config.url + "/ikki/admin_fiat/withdraw_reset",
    method: "post",
    data,
  });
}

// 提现退回
export function refundWithdrawOrders(data) {
  return axios({
    url: config.url + "/ikki/admin_fiat/withdraw_refund",
    method: "post",
    data,
  });
}

// 提现通过
export function refundStarWithdrawPass(data) {
  return axios({
    url: config.url + "/ikki/admin_fiat/set_withdraw_check_approved",
    method: "post",
    data,
  });
}

// 提现拒绝
export function refundStarWithdrawReject(data) {
  return axios({
    url: config.url + "/ikki/admin_fiat/set_withdraw_check_reject",
    method: "post",
    data,
  });
}

export function getOnshelfOrders(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/get_on_shelf_order_list",
    method: "post",
    data,
  });
}

export function offShelfOrders(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/force_off_shelf",
    method: "post",
    data,
  });
}

export function getUserAsset(data) {
  return axios({
    url: config.url + "/ikki/admin_base/get_user_nft_asset_info",
    method: "post",
    data,
  });
}

export function searchUserByPhone(phone) {
  return axios({
    url: config.url + "/v1/user/search",
    method: "get",
    params: {
      filter: "account",
      value: phone,
    },
  });
}

// 获取用户资产
export function getUserWorks(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_user_assets/get_works",
    method: "post",
    data,
  });
}

// 获取用户多钱包相关
export function getUserSeeds(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_user_assets/get_seed_info",
    method: "post",
    data,
  });
}

// 退款订单查询
export function getRefundOrderStatus(data) {
  return axios({
    url: config.url + "/v1/phoenix_admin/refund_order_status",
    method: "post",
    data,
  });
}

export function getPriorityPurchases() {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/get_priority_purchases",
    method: "post",
  });
}

export function removePriorityPurchase(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/delete_priority_purchase",
    method: "post",
    data,
  });
}

export function setPriorityPurchases(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/edit_priority_purchase",
    method: "post",
    data,
  });
}

export function setPriorityPurchasesStatus(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/edit_priority_purchase_status",
    method: "post",
    data,
  });
}

export function getPriorityPurchasesNewNfts() {
  return axios({
    url: config.url + "/phoenix/user_nft/priority_purchase/new_nft",
    method: "post",
  });
}

export function getMedals() {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/nft_intl_get_medals",
    method: "post",
  });
}

export function eventsSnapshot(data) {
  return axios({
    url: config.url + "/phoenix/admin_activity_manage/nft_activity_snapshot",
    method: "post",
    data,
  });
}

export function getTop1000Contribution(ranking, type) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/zodiac_get_own_contribution_top_thousand",
    method: "post",
    data: {
      ranking,
      type,
    },
  });
}

export function getBatchList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/get_batches",
    method: "post",
    data,
  });
}

export function addBatch(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/add_batch",
    method: "post",
    data,
  });
}

export function editBatch(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/update_batch",
    method: "post",
    data,
  });
}

export function getBatchNFTList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/get_batch_nfts",
    method: "post",
    data,
  });
}

export function getSellWorksStatistics(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/nft_work/statistics",
    method: "post",
    data,
  });
}

export function lockNFTItem(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/nft_intl_lock",
    method: "post",
    data,
  });
}

export function getUserOpeningAccount(data) {
  return axios({
    url: config.url + "/ikki/admin_fiat/get_user_banks",
    method: "post",
    data,
  });
}

export function getPurchaseLimitConfig() {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/reserve_purchase_txid_limit",
    method: "post",
  });
}

export function getNFTNameByTxid(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/get_nft_name",
    method: "post",
    data,
  });
}

export function deleteSnapshot() {
  return axios({
    url: config.url + "/phoenix/admin_activity_manage/delete_prev_snapshot",
    method: "post",
  });
}

export function getComposeBatches() {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/get_generation_batch_list",
    method: "post",
  });
}

export function addComposeBatch(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/add_generation_batch",
    method: "post",
    data,
  });
}

export function deleteComposeBatch(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/del_generation_batch",
    method: "post",
    data,
  });
}

export function getComposeBatchDetail(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/get_generation_batch_info",
    method: "post",
    data,
  });
}

export function setComposeBatchDetail(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/set_generation_albumn",
    method: "post",
    data,
  });
}

export function unLockNFTItem(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/nft_intl_unlock",
    method: "post",
    data,
  });
}

export function updateComposeBatch(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/update_generation_batch",
    method: "post",
    data,
  });
}

export function auditNFT2ComposeBatch(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/add_albumn_to_generation_batch",
    method: "post",
    data,
  });
}
// 批量拒绝用户认证申请
export function rejectAchievement(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_identification/nft_intl_reject_achievement",
    method: "post",
    data,
  });
}

export function nftAuthAdd(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_authority/add",
    method: "post",
    data,
  });
}

// 新增授权范围管理的艺术家
export function addArtistForAuthority(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_authority/add_artist",
    method: "post",
    data,
  });
}

export function nftAuthList() {
  return axios({
    url: config.url + "/phoenix/admin_nft_authority/list",
    method: "post",
  });
}

export function nftAuthDeleteArtist(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_authority/delete_artist",
    method: "post",
    data,
  });
}

export function nftAuthDelete(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_authority/delete",
    method: "post",
    data,
  });
}

export function nftAuthUpdate(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_authority/update",
    method: "post",
    data,
  });
}

export function getCopyrightLicenseRateList() {
  return axios({
    url: config.url + "/phoenix/admin_nft_copyright_rate/list",
    method: "post",
  });
}

export function updateCopyrightLicenseRate(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_copyright_rate/update",
    method: "post",
    data,
  });
}

export function getFlyEventData() {
  return axios({
    url: config.url + "/phoenix/admin_activity_manage/get_treasure_box_material_info",
    method: "post",
  });
}

// 竞选艺术家列表
export function getCampaignArtistsList() {
  return axios({
    url: config.url + "/phoenix/admin_activity_manage/vote_history",
    method: "post",
  });
}

export function addVoteCount(data) {
  return axios({
    url: config.url + "/phoenix/admin_activity_manage/add_fake_vote",
    method: "post",
    data,
  });
}

// 艺术家管理
export function getArtistManage(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/artist_manage_list",
    method: "post",
    data,
  });
}

// 艺术家资料编辑
export function setArtistManage(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/update_artist_profile",
    method: "post",
    data,
  });
}

// 艺术家分红记录
export function getArtistBonus(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/artist_partner_bonus_list",
    method: "post",
    data,
  });
}

// 合伙人分红
export function setPartnerBonus(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/pay_artist_partner_bonus",
    method: "post",
    data,
  });
}

// 系列管理
export function getCollectionList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/collection_list",
    method: "post",
    data,
  });
}

// 小店作品审核列表
export function getShopNFTList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/shop_qualification_audit_list",
    method: "post",
    data,
  });
}
// 小店作品审核
export function EditShopNFT(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/qualification_audit",
    method: "post",
    data,
  });
}

// 艺术家统计列表
export function getArtistList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/artist_analyse_list",
    method: "post",
    data,
  });
}

// 合成品列表
export function getSyntheticList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/get_generation_batch_list",
    method: "post",
    data,
  });
}

export function subtractVoteCount(data) {
  return axios({
    url: config.url + "/phoenix/admin_activity_manage/cancel_fake_vote",
    method: "post",
    data,
  });
}

// 审核合成
export function reviewSynthetics(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/audit_generation_batch",
    method: "post",
    data,
  });
}

// 统计分析-获取交易数据
export function getTradData(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_manage_data/nft_intl_get_nft_trade_statistic",
    method: "post",
    data,
  });
}

// 统计分析-获取账号数据
export function getAccountData(data) {
  return axios({
    url: config.url + "/v1/user/statistics/account_info",
    method: "post",
    data,
  });
}

// 统计分析-获取持仓数据
export function getHoldData(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_manage_data/nft_intl_get_nft_hold_statistics",
    method: "post",
    data,
  });
}
// 统计分析-导出交易数据
export function exportTradData(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_manage_data/nft_intl_export_nft_trade_statistic",
    method: "post",
    data,
  });
}

// 统计分析-导出账号数据
export function exportAccountData(data) {
  return axios({
    url: config.url + "/v1/user/statistics/export_account_info",
    method: "post",
    data,
  });
}

// 统计分析-导出持仓数据
export function exportHoldData(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_manage_data/nft_intl_export_nft_hold_statistics",
    method: "post",
    data,
  });
}
// 统计分析-获取累计数据
export function getHistoryData(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_manage_data/nft_intl_get_history_statistics",
    method: "post",
    data,
  });
}

// 新增/编辑批次
export function addGenerationBatch(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_management/add_generation_batch",
    method: "post",
    data,
  });
}

// 核销记录列表
export function getVerificationList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/write_off_record_list",
    method: "post",
    data,
  });
}

//艺术家活动列表
export function getPromotionStatisticList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/promotion_statistic_list",
    method: "post",
    data,
  });
}

//艺术家福袋统计
export function getLuckyBagStatisticList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/lucky_bag_statistic_list",
    method: "post",
    data,
  });
}

//福袋销售记录
export function getLuckyBagSaleStatisticList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/lucky_bag_sale_statistic_list",
    method: "post",
    data,
  });
}

//众筹项目查询
export function getCrowdfundingProjectList(data) {
  return axios({
    url: config.url + "/ikki/admin_crowdfunding_manage/get_crowdfunding_project",
    method: "post",
    data,
  });
}

//众筹项目操作
export function setCrowdfundingProject(data) {
  return axios({
    url: config.url + "/ikki/admin_crowdfunding_manage/operate_crowdfunding_project",
    method: "post",
    data,
  });
}

// v2.2 缓存列表
export function getCacheTxidList(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/nft_assets_cache_txid_list",
    method: "post",
    data,
  });
}

// v2.2 新增缓存
export function addCacheTxid(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/nft_assets_cache_add_txid",
    method: "post",
    data,
  });
}

// v2.2 新增缓存获取名称和铸造数量
export function getCacheTxidName(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/get_nft_name",
    method: "post",
    data,
  });
}

// v2.2 删除缓存
export function delCacheTxid(data) {
  return axios({
    url: config.url + "/phoenix/admin_nft_order/nft_assets_cache_delete_txid",
    method: "post",
    data,
  });
}

// tiertop 获取 launchpad 的合集
export function getLaunchPadCollection(data) {
  return axios({
    url: config.tierTopUrl + "/btc/ordinals/search_mint_collections",
    method: "post",
    data,
  });
}
// tiertop 获取合集的详情信息
export function getLaunchPadCollectionDetail(data) {
  return axios({
    url: config.tierTopUrl + "/btc/ordinals/get_audit_collection_detail",
    method: "post",
    data,
  });
}

// tiertop 审核合集
export function auditPadCollection(data) {
  return axios({
    url: config.tierTopUrl + "/btc/ordinals/audit_collection",
    method: "post",
    data,
  });
}